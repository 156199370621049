import Swiper, { Navigation, Pagination } from "swiper";
import Scrollbar from "smooth-scrollbar";
import "swiper/swiper.min.css";
import { gsap } from "gsap";
import bindContactForm from "./form";

window.addEventListener("load", () => {
  // bind contact form
  bindContactForm();

  // Sliders
  const sponsorSlider = new Swiper(".testimonials__container .swiper", {
    modules: [Navigation, Pagination],
    slidesPerView: "1",
    pagination: {
      el: ".swiper-pagination",
      type: "custom",
      renderCustom(swiper, current, total) {
        return `${`0${current}`.slice(-2)}/${`0${total}`.slice(-2)}`;
      },
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
  });

  sponsorSlider.init();

  // Sliders
  // eslint-disable-next-line no-new
  new Swiper(".clients__container .swiper", {
    modules: [Navigation, Pagination],
    slidesPerView: "auto",
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
  });

  sponsorSlider.init();

  // Input Label
  const labeledInput = document.getElementsByClassName("focus-element");

  function focusLabel() {
    [...labeledInput].forEach((el) => {
      el.addEventListener("input", () => {
        el.focus(() => {
          el.nextElementSibling.classList.add("is-active");
        });

        // eslint-disable-next-line no-param-reassign
        el.nextElementSibling.nextElementSibling.querySelector(
          ".count"
        ).innerHTML = el.value.length;

        if (!el.value) {
          el.nextElementSibling.classList.remove("is-active");
        } else {
          el.nextElementSibling.classList.add("is-active");
        }
      });
    });
  }

  focusLabel();

  const submitButton = document.querySelector('input[type="submit"]');
  const email = document.querySelector('input[type="email"]');
  const name = document.querySelector('input[type="text"]');
  const emailReg =
    /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/;
  const nameReg = /\p{L}+/gu;

  submitButton.addEventListener("click", () => {
    [...labeledInput].forEach((el) => {
      function error(event) {
        event.preventDefault();
        el.parentElement.classList.add("error");
      }

      el.parentElement.classList.remove("error");
      el.addEventListener("invalid", error);
    });
  });

  email.addEventListener("keyup", () => {
    if (!email.value.match(emailReg) && email.value !== "") {
      email.parentElement.classList.add("error");
    } else {
      email.parentElement.classList.remove("error");
    }
  });

  name.addEventListener("keyup", () => {
    if (!name.value.match(nameReg) && name.value !== "") {
      name.parentElement.classList.add("error");
    } else {
      name.parentElement.classList.remove("error");
    }
  });

  // Respons Textarea
  const tx = document.getElementsByTagName("textarea");
  function OnInput() {
    this.style.height = "auto";
    this.style.height = `${this.scrollHeight}35px`;

    if (!this.value) {
      this.style.height = "32px";
    } else {
      this.style.height = "auto";
      this.style.height = `${this.scrollHeight}px`;
    }
  }
  for (let i = 0; i < tx.length; i += 1) {
    tx[i].setAttribute(
      "style",
      `height:${tx[i].scrollHeight}px;overflow-y:hidden;`
    );
    tx[i].addEventListener("input", OnInput, false);
  }

  // Mobile Menu
  const menu = document.querySelector(".mobile-menu__button");
  const mobileMenu = document.querySelector(".mobile-menu");
  const body = document.querySelector(".body");
  const mobileLink = document.querySelectorAll(".mobile-link");

  menu.addEventListener("click", () => {
    menu.classList.toggle("open");
    mobileMenu.classList.toggle("active");
    body.classList.toggle("menu-open");
  });

  mobileLink.forEach((el) => {
    el.addEventListener("click", () => {
      menu.classList.remove("open");
      mobileMenu.classList.remove("active");
      body.classList.remove("menu-open");
    });
  });

  /**
   * disable/enable scrolling solution
   * https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
   */
  // left: 37, up: 38, right: 39, down: 40,
  // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
  const keys = {
    37: 1,
    38: 1,
    39: 1,
    40: 1,
  };

  function preventDefault(e) {
    e.preventDefault();
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
    return true;
  }

  // modern Chrome requires { passive: false } when adding event
  let supportsPassive = true;
  try {
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        // eslint-disable-next-line getter-return
        get() {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {
    // ignore error
  }

  const wheelOpt = supportsPassive ? { passive: false } : false;
  const wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

  // call this to Disable
  function disableScroll() {
    const scrollEl = document.querySelector("#scrollbar");
    scrollEl.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
    scrollEl.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    scrollEl.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
    scrollEl.addEventListener("keydown", preventDefaultForScrollKeys, false);
  }

  // call this to Enable
  function enableScroll() {
    const scrollEl = document.querySelector("#scrollbar");
    scrollEl.removeEventListener("DOMMouseScroll", preventDefault, false);
    scrollEl.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    scrollEl.removeEventListener("touchmove", preventDefault, wheelOpt);
    scrollEl.removeEventListener("keydown", preventDefaultForScrollKeys, false);
  }

  disableScroll();

  // SmoothScroll
  const hero = document.querySelector(".hero");
  const scrollBar = Scrollbar.init(document.querySelector("#scrollbar"));
  const links = [
    ...document.querySelectorAll(".menu-list .mobile-link"),
    ...document.querySelectorAll(".hero__list--link"),
    document.querySelector(".hero__anchor"),
  ];

  links.forEach((el) => {
    el.addEventListener("click", () => {
      const href = el.getAttribute("data-href");
      const anchor = document.querySelector(href);
      const scrollToHere = anchor.offsetTop;
      scrollBar.scrollTo(0, scrollToHere, 1000);
    });
  });

  if (window.innerWidth >= 1200) {
    scrollBar.addListener((status) => {
      const { offset } = status;
      hero.style.top = `${offset.y}px`;
    });
  } else {
    scrollBar.addListener((status) => {
      const { offset } = status;

      if (offset.y >= hero.offsetHeight) {
        menu.style.display = "block";
      } else {
        menu.style.display = "none";
      }
    });
  }

  document.querySelector(".scrollbar-track-y").classList.add("scrollbar-hide");

  // animation
  // logo animation
  function animateLogo() {
    // simple SplitText implementation
    const heroLogo = document.querySelector(".hero__logo");
    heroLogo.innerHTML = `<span>${heroLogo.innerHTML
      .split("")
      .join("</span><span>")}</span>`;

    const chars = heroLogo.querySelectorAll("span");

    // Set up the anim
    const heroTL = gsap.timeline();
    heroTL
      .to(".hero__logo", { opacity: 1, duration: 0 })
      .to(chars, { duration: 0.5, y: 0, stagger: 0.1 });
  }

  function animateHeroList() {
    const linkEls = document.querySelectorAll(".hero__list--link");
    const listElements = document.querySelectorAll(
      ".hero__list--link .link-scroll"
    );
    const listNumberEls = document.querySelectorAll(
      ".hero__list--item > span:nth-child(1)"
    );
    const listLines = document.querySelectorAll(".hero__list--item .link-line");

    const heroListTL = gsap.timeline();
    heroListTL
      .to(listNumberEls, {
        duration: 0.4,
        y: "-3px", // because text underline
        stagger: 0.1,
      })
      .to(
        listElements,
        {
          duration: 0.4,
          y: 0,
          stagger: 0.1,
          // eslint-disable-next-line no-use-before-define
          onStart: () => animateHeroListLetter(),
        },
        "-=0.6"
      )
      .to(
        listLines,
        {
          duration: 1,
          width: "100%",
          stagger: 0.1,
        },
        "-=0.4"
      );

    // add onhover enevt listeners
    linkEls.forEach((linkEl) => {
      linkEl.addEventListener("mouseenter", () => {
        const linkLine = linkEl.querySelector(".link-line");
        const linkElSpan = linkEl.querySelector(".link-scroll");

        const linkLineTL = gsap.timeline();
        linkLineTL
          .to(linkLine, {
            duration: 0.3,
            x: "100%",
            onStart: () => {
              const linkElSpanTL = gsap.timeline();
              linkElSpanTL
                .to(linkElSpan, { duration: 0.15, y: "-20px" })
                .to(linkElSpan, { duration: 0, y: "20px" })
                .to(linkElSpan, { duration: 0.15, y: "0px" });
            },
          })
          .to(linkLine, { duration: 0, x: "-100%" })
          .to(linkLine, { duration: 0.3, x: "0%" });
      });
    });
  }

  function animateChars(element, minDuration = 1000) {
    const letters = "abcdefghijklmnopqrstuvwxyz";
    const capitalLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const original = Array.from(element.innerText);
    const text = Array.from(element.innerText);
    text.forEach((char, i) => {
      const alphabet = char === char.toUpperCase() ? capitalLetters : letters;
      let duration = minDuration + i * 25;
      if (char === " ") return;
      const interval = setInterval(() => {
        if (duration > 0) {
          text[i] = alphabet.charAt(Math.random() * alphabet.length);
          // eslint-disable-next-line no-param-reassign
          element.innerText = text.join("");
          duration -= 70;
        } else {
          clearInterval(interval);
          text[i] = original[i];
          // eslint-disable-next-line no-param-reassign
          element.innerText = text.join("");
        }
      }, 40);
    });
  }

  // hero info text animation
  function animateHeroInfoText() {
    // simple SplitText implementation
    const heroInfoText = document.querySelector(".hero__info");

    animateChars(heroInfoText);
  }

  function animateHeroListLetter() {
    const heroListItems = document.querySelectorAll(
      ".hero__list--item .link-scroll"
    );

    for (const heroListItem of heroListItems) {
      animateChars(heroListItem);
    }
  }

  function animateHeroBottomLink() {
    const heroBottomLink = document.querySelector(".hero__anchor .link-scroll");

    animateChars(heroBottomLink);
  }

  function animateHeroInfo() {
    const heroInfoTL = gsap.timeline();
    heroInfoTL
      .to(".hero__info", { duration: 1, opacity: 1, y: 0 })
      .to(
        ".hero__bottom",
        {
          duration: 1,
          opacity: 1,
          y: 0,
          onStart: () => animateHeroBottomLink(),
        },
        "-=0.5"
      )
      .to(
        ".hero__bottom .link-line",
        {
          duration: 1,
          width: "100%",
          stagger: 0.1,
        },
        "-=0.4"
      );

    const bottomEl = document.querySelector(".hero__anchor");
    const bottomLinkEl = bottomEl.querySelector(".link-scroll");
    const bottomLinkLine = bottomEl.querySelector(".link-line");

    bottomEl.addEventListener("mouseenter", () => {
      const bottomLinkLineTL = gsap.timeline();
      bottomLinkLineTL
        .to(bottomLinkLine, {
          duration: 0.3,
          x: "100%",
          onStart: () => {
            const bottomLinkElTL = gsap.timeline();
            bottomLinkElTL
              .to(bottomLinkEl, { duration: 0.15, y: "-20px" })
              .to(bottomLinkEl, { duration: 0, y: "20px" })
              .to(bottomLinkEl, { duration: 0.15, y: "0px" });
          },
        })
        .to(bottomLinkLine, { duration: 0, x: "-100%" })
        .to(bottomLinkLine, { duration: 0.3, x: "0%" });
    });
  }

  // animate text on the right side
  function animateInfoText() {
    const heroInfoTitleTL = gsap.timeline();
    heroInfoTitleTL
      .to(".info__title", {
        duration: 1,
        opacity: 1,
        y: 0,
        // onStart: () => animateTechStackItems(),
      })
      .to(".info__list", {
        duration: 0,
        opacity: 1,
      });
  }

  function animateSVG() {
    const svg = document.querySelector(".info__svg-content");
    const circles = svg.querySelectorAll("circle");
    const texts = svg.querySelectorAll("text");

    const circlesTL = gsap.timeline();
    circlesTL
      .to(circles, {
        cx(index, target, targets) {
          let center = "0%";
          switch (targets.length - index) {
            case 1:
              center = "13%";
              break;
            case 2:
              center = "20.5%";
              break;
            case 3:
              center = "28%";
              break;
            case 4:
              center = "35.5%";
              break;
            case 5:
              center = "42.5%";
              break;
            case 6:
              center = "50%";
              break;
            default:
              break;
          }
          return center;
        },
        r(index, target, targets) {
          let radius = "0%";
          switch (targets.length - index) {
            case 1:
              radius = "12%";
              break;
            case 2:
              radius = "19.5%";
              break;
            case 3:
              radius = "27%";
              break;
            case 4:
              radius = "34.5%";
              break;
            case 5:
              radius = "41.5%";
              break;
            case 6:
              radius = "49%";
              break;
            default:
              break;
          }
          return radius;
        },
        ease: "power3.out",
        duration: 2,
        stagger: 0.2,
      })
      .to(
        texts,
        {
          "fill-opacity": 0.8,
          ease: "power3.out",
          duration: 2,
          stagger: 0.2,
        },
        "-=1.5"
      );
  }

  function showLoadingMessage() {
    gsap.to(".hero__loading-p", { duration: 0.5, y: 0, stagger: 0.1 });
  }

  function hideLoadingMessage() {
    gsap.to(".hero__loading-p", { duration: 0.5, y: -40, stagger: 0.1 });
  }

  function onStartBackgroundAnimation() {
    animateLogo();
    animateHeroList();
    animateInfoText();
    // animateHeroListLine();
    animateHeroInfoText();
    animateSVG();
    hideLoadingMessage();
  }

  function onCompleteBackgroundAnimation() {
    // @workaround
    // original markup has blue background to bottom text paragraph,
    // so we have to show it right after blue screen filled
    animateHeroInfo();
    // show scroller
    document
      .querySelector(".scrollbar-track-y")
      .classList.remove("scrollbar-hide");
    enableScroll();
  }

  function startBackgroundAnimation() {
    const counterDuration = 0.8;
    const heroBackground = document.querySelector(".hero__background");
    const backgroundTL = gsap.timeline();
    backgroundTL
      .to(heroBackground, {
        duration: counterDuration,
        height: "8vh",
        "margin-top": "92vh",
        onStart: () => showLoadingMessage(),
      })
      .to(heroBackground, {
        duration: counterDuration,
        height: "36vh",
        "margin-top": "64vh",
      })
      .to(heroBackground, {
        duration: counterDuration,
        height: "100vh",
        "margin-top": "0vh",
        onComplete: () => {
          // set fixed background height on mobile
          if (window.innerWidth < 1200) {
            const height = window.innerHeight;
            heroBackground.style.height = `${height}px`;
          }
        },
      })
      .to(".hero__background", {
        duration: counterDuration,
        width: "100%",
        onStart: () => onStartBackgroundAnimation(),
        onComplete: () => onCompleteBackgroundAnimation(),
      });
  }

  function startMobileBackgroundAnimation() {
    const counterDuration = 0.8;
    const heroBackground = document.querySelector(".hero__background");
    const backgroundTL = gsap.timeline();
    const loadingPadding = 18;

    const newVal = (v) => {
      return (v * (100 - 2 * loadingPadding)) / 100;
    };

    backgroundTL
      .to(heroBackground, {
        duration: 0,
        right: "50vw",
        "margin-top": `${newVal(100 + loadingPadding)}vh`,
      })
      .to(heroBackground, {
        duration: counterDuration,
        height: `${newVal(8)}vh`,
        "margin-top": `${newVal(92 + loadingPadding)}vh`,
        onStart: () => showLoadingMessage(),
      })
      .to(heroBackground, {
        duration: counterDuration,
        height: `${newVal(36)}vh`,
        "margin-top": `${newVal(64 + loadingPadding)}vh`,
      })
      .to(heroBackground, {
        duration: counterDuration,
        height: `${newVal(100 - loadingPadding)}vh`,
        "margin-top": `${newVal(0 + loadingPadding * 2)}vh`,
      })
      .to(".hero__background", {
        duration: counterDuration,
        width: "100%",
        right: "0vw",
        height: "100vh",
        "margin-top": "0vh",
        onStart: () => onStartBackgroundAnimation(),
        onComplete: () => {
          // set fixed background height on mobile
          if (window.innerWidth < 1200) {
            const height = window.innerHeight;
            heroBackground.style.height = `${height}px`;
          }

          onCompleteBackgroundAnimation();
        },
      });
  }

  function startCountdownAnimation() {
    const counterDuration = 0.8;
    const counterTL = gsap.timeline();
    counterTL
      .to(".hero__counter-content", { duration: counterDuration, y: "-25%" })
      .to(".hero__counter-content", { duration: counterDuration, y: "-50%" })
      .to(".hero__counter-content", { duration: counterDuration, y: "-75%" })
      .to(".hero__counter-content", { duration: counterDuration, y: "-100%" });
  }

  // start animations
  if (window.innerWidth < 1200) {
    startMobileBackgroundAnimation();
  } else {
    startBackgroundAnimation();
  }
  startCountdownAnimation();
});

window.addEventListener("resize", () => {
  const hero = document.querySelector(".hero");
  const scrollBar = Scrollbar.init(document.querySelector("#scrollbar"));
  const menu = document.querySelector(".mobile-menu__button");

  if (window.innerWidth >= 1200) {
    scrollBar.addListener((status) => {
      const { offset } = status;
      hero.style.top = `${offset.y}px`;
    });
  } else {
    scrollBar.addListener((status) => {
      const { offset } = status;

      if (offset.y >= hero.offsetHeight) {
        menu.style.display = "block";
      } else {
        menu.style.display = "none";
      }
    });
  }
});

// update hero background on resize
window.addEventListener("resize", () => {
  const heroBackground = document.querySelector(".hero__background");

  const height = window.innerHeight;
  heroBackground.style.height = `${height}px`;

  if (window.innerWidth >= 1200) {
    heroBackground.style.right = `${50}vw`;
  } else {
    heroBackground.style.right = `${0}vw`;
  }
});

if (window.innerWidth < 1200) {
  const convertStyle = () => {
    const height = window.innerHeight;
    const elements = Array.from(document.getElementsByClassName("full-vh"));
    for (const element of elements) {
      element.style.height = `${height}px`;
    }
  };
  window.addEventListener("resize", convertStyle);
  window.addEventListener("DOMContentLoaded", convertStyle);
}

export default function bindContactForm() {
  const formSubmit = document.querySelector("#form-submit");
  const form = document.querySelector("#contact-form");
  const successNotice = document.querySelector("#success-notice");

  formSubmit.addEventListener("click", async function (e) {
    e.preventDefault();
    const {
      name: nameField,
      email: emailField,
      message: messageField,
    } = form.elements;
    const values = {
      name: nameField.value,
      email: emailField.value,
      message: messageField.value,
    };
    const isValid =
      nameField.checkValidity() &&
      emailField.checkValidity() &&
      messageField.checkValidity();
    if (!isValid) {
      return;
    }
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute("6LcnPNkZAAAAAEneYTMEUDRBGWXjqDMFuA0045-x", {
          action: "submit",
        })
        .then(async function (token) {
          values.token = token;
          const result = await fetch(
            "https://m4aj3a0agi.execute-api.eu-central-1.amazonaws.com/prod/contact",
            {
              method: "POST",
              mode: "cors",
              credentials: "omit",
              cache: "no-cache",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(values),
            }
          );
          if (result.ok) {
            successNotice.style.display = "block";
            form.reset();
            return;
          }
          successNotice.innerHTML =
            "Something went wrong... Please try again later.";
          successNotice.style.display = "block";
        });
    });
  });
}
